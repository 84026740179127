<template>
  <div>
    <div class="maintitle">回收哥列表</div>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="3">
        <el-button type="success" @click="addhuishouge">添加回收哥</el-button>
      </el-col>
    </el-row>
    <el-table :data="list" border>
      <el-table-column align="center" fixed prop="name" label="姓名" width="150"></el-table-column>
      <el-table-column align="center" fixed prop="idcardno" label="身份证号码" width="200"></el-table-column>
      <el-table-column align="center" fixed prop="tel" label="手机号" width="150"></el-table-column>
      <el-table-column align="center" prop="area_text" label="所属地区" min-width="200"></el-table-column>
      <el-table-column sortable align="center" prop="logintime" label="最后登录时间" width="200"></el-table-column>
      <el-table-column align="center" fixed="right" label="状态" width="80">
        <template slot-scope="scope">
          <span v-if='scope.row.status==1'>正常</span>
          <span v-else>停用</span>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="450">
        <template slot-scope="scope">
          <el-button-group>
            <el-button size="small" @click="mdfy(scope)">修改</el-button>
            <el-button size="small" @click="resetpassword(scope)">重置密码</el-button>
            <el-button size="small" @click="resetpaypassword(scope)">重置支付密码</el-button>
            <!-- <el-button size="small" @click="add(scope)">回收</el-button>-->
            <el-button
              size="small"
              @click="$router.push('/huishouge/transaction/'+scope.row.uuid)"
            >查看流水</el-button>
            <el-button size="small" @click="deluser(scope)">删除</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="page"
        :page-sizes="pagesizes"
        :page-size="pagesize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
    ></el-pagination>
    <el-dialog title="回收" :visible.sync="AddDiv">
      <el-form :model="addform">
        <el-form-item label="货物类型" :label-width="formLabelWidth" class="text-left">
          <el-select v-model="addform.goodstype" placeholder="请选择货物类型">
            <el-option
                v-for="(item,index) in goodstype"
                :key="index"
                :label="item.type"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="货物重量" :label-width="formLabelWidth">
          <el-input v-model="addform.weight" placeholder="请输入货物重量(公斤)"></el-input>
        </el-form-item>
        <el-form-item label="回收价格" :label-width="formLabelWidth" class="text-left">
          <el-input v-model="addform.price" type="number" placeholder="请输入货物单价(元)"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="AddDiv = false">取 消</el-button>
        <el-button type="primary" @click="addcfrm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="充值" :visible.sync="AddYueDiv">
      <el-form :model="addyueform">
        <el-form-item label="充值金额" :label-width="formLabelWidth" class="text-left">
          <el-input v-model="addyueform.yue" type="number" placeholder="请输入充值金额(元)"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="AddYueDiv = false">取 消</el-button>
        <el-button type="primary" @click="addyuecfrm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      list: [],
      page: 1, //当前页数
      total: 0, //信息总数（所有页）
      crntdistrict: "", //当前选择县/区
      district: [], //县/区列表
      crntscope: [], //当前修改回收哥
      AddDiv: false, //回收对话框
      AddYueDiv: false, //回收哥充值
      formLabelWidth: "150px",
      addform: {
        goodstype: 1,
        weight: "",
        price: "",
        uuid: ""
      }, //回收内容
      addyueform: {
        yue: "",
        uuid: ""
      },
      goodstype: [
        {
          id: 1,
          type: "纸壳/书本"
        },
        {
          id: 2,
          type: "旧衣服"
        }
      ]
    };
  },
  computed: {},
  methods: {
    getlist: function () {
      let param = {
        pagesize: this.pagesize,
        page: this.page
      };
      this.$axios({
        url: this.HOST + "/jiameng/huishouge/getList",
        method: "post",
        data: param
      }).then(res => {
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    srch() {
      this.page = 1;
      this.getlist();
    },
    getAreaList() {
      let url = this.HOST + "/jiameng/index/getAreaList";
      this.$axios({
        method: "post",
        url: url,
        data: {}
      }).then(res => {
        if (res.data.status == 1) {
          this.district = res.data.list;
        } else {
          return [];
        }
      });
    },
    mdfy(scope) {
      this.$router.push("/huishouge/mdfy/" + scope.row.uuid);
    },
    addhuishouge() {
      this.$router.push("/huishouge/add");
    },
    resetpassword(scope) {
      let uuid = scope.row.uuid;
      this.$axios({
        method: "post",
        url: this.HOST + "/jiameng/huishouge/resetpassword",
        data: {id: uuid}
      }).then(res => {
        if (res.data.status == 1) {
          this.$alert(
              scope.row.name + "的密码已经修改为" + res.data.info,
              "修改成功"
          );
        } else if (res.data.status == 0) {
          this.$message({
            type: "error",
            message: res.data.info
          });
        }
      });
    },
    resetpaypassword(scope) {
      let uuid = scope.row.uuid;
      this.$axios({
        method: "post",
        url: this.HOST + "/jiameng/huishouge/resetpaypassword",
        data: {id: uuid}
      }).then(res => {
        if (res.data.status == 1) {
          this.$alert(
              scope.row.name + "的支付密码已经修改为" + res.data.info,
              "修改成功"
          );
        } else if (res.data.status == 0) {
          this.$message({
            type: "error",
            message: res.data.info
          });
        }
      });
    },
    add(scope) {
      this.addform = {
        uuid: scope.row.uuid,
        weight: "",
        price: "",
        goodstype: 1
      };
      this.AddDiv = true;
    },
    addcfrm() {
      this.$axios({
        method: "post",
        url: this.HOST + "/jiameng/huishouge/addOrder",
        data: {
          info: this.addform
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.$message({
            type: "info",
            message: "操作成功"
          });
          this.AddDiv = false;
        }
      });
    },
    addyue(scope) {
      this.addyueform.uuid = scope.row.uuid;
      this.addyueform.yue = "";
      this.AddYueDiv = true;
      this.crntscope = scope;
    },
    addyuecfrm() {
      this.$axios({
        method: "post",
        url: this.HOST + "/jiameng/huishouge/addyue",
        data: this.addyueform
      }).then(res => {
        if (res.data.status == 1) {
          this.$message({
            type: "info",
            message: "充值成功"
          });
          this.AddYueDiv = false;
        } else {
          this.$message({
            type: "error",
            message: "充值失败"
          });
        }
      });
    },
    deluser(scope) {
      this.$confirm("确定要删除" + scope.row.name + "吗？本操作不可恢复！！！", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios({
          method: "post",
          url: this.HOST + "/jiameng/huishouge/del",
          data: {
            id: scope.row.uuid
          }
        }).then(res => {
          if (res.data.status == 1) {
            this.$message({
              type: "info",
              message: "操作成功"
            });
            this.list.splice(scope.$index, 1);
          } else {
            this.$message({
              type: "error",
              message: res.data.info
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消"
        });
      });
    }
  },
  created: function () {
    this.getlist();
    this.getAreaList();
  }
};
</script>

<style>
.el-table .cell {
  white-space: pre-line;
}
</style>